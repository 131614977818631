import 'bootstrap';

document.addEventListener("DOMContentLoaded", function(){
    window.addEventListener('scroll', function() {
        if (window.scrollY > 401) {
          document.getElementById('navbar_top').classList.add('sillycolor');
          // add padding top to show content behind navbar
          navbar_height = document.querySelector('.navbar').offsetHeight;

        } else {
          document.getElementById('navbar_top').classList.remove('sillycolor');
           // remove padding top from body
          document.body.style.paddingTop = '0';
        } 
    });
  }); 

const hero2 = document.querySelectorAll(".hero2")

const observer = new IntersectionObserver(entries => {
  console.log(entries)
});

observer.observe(hero2[0]);



